import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleTagManager = ({ gtmId }) => {
    const location = useLocation();

    useEffect(() => {
        // Initialize GTM
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', gtmId);
    }, [gtmId]);

    useEffect(() => {
        // Push new page view to GTM dataLayer on route change
        window.dataLayer.push({
            event: 'pageview',
            page: location.pathname,
            parameters: {
                // You can add more details about the page or route here if needed
            }
        });
    }, [location]);

    return (
        <noscript>
            <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
                title="Google Tag Manager"
            />
        </noscript>
    );
};

export default GoogleTagManager;
