import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from './NavBar';
import { QuizContext } from '../context/QuizContext'; // Assuming you have a QuizContext
import './QuestionComponent.css';

const QuestionComponent = () => {
    const { questionsArray, setQuestionsArray, formData, setFormData } = useContext(QuizContext);
    const { questionIndex } = useParams();
    const navigate = useNavigate();

    const sumAndSortOptions = () => {
        // Get all selected options
        const selectedOptions = questionsArray.flatMap(question => question.options.filter(option => option.selected));
        console.log(selectedOptions);

        // Calculate the sum of a1, a2, a3, a4 for each selected option
        const arquetypeSums = [
            { key: 'visionario', value: 0 },
            { key: 'criador', value: 0 },
            { key: 'estrategista', value: 0 },
            { key: 'guardiao', value: 0 }
        ];

        selectedOptions.forEach(option => {
            arquetypeSums[0].value += option.a1;
            arquetypeSums[1].value += option.a2;
            arquetypeSums[2].value += option.a3;
            arquetypeSums[3].value += option.a4;
        });

        console.log("arquetype", arquetypeSums);

        const sortedArquetypes = arquetypeSums.sort((a, b) => b.value - a.value);

        console.log("arquetype sorted", sortedArquetypes);

        setFormData({ ...formData, cf_resultado_do_quiz: sortedArquetypes[0].key });
    };


    const currentQuestionIndex = parseInt(questionIndex, 10) - 1;
    const question = questionsArray[currentQuestionIndex];

    const handleOptionClick = (optionIndex) => {
        const updatedQuestions = questionsArray.map((q, index) => {
            if (index === currentQuestionIndex) {
                return {
                    ...q,
                    options: q.options.map((option, idx) => ({
                        ...option,
                        selected: idx === optionIndex
                    }))
                };
            }
            return q;
        });
        setQuestionsArray(updatedQuestions);
    };

    const handlePreviousClick = () => {
        navigate(`/quiz/${currentQuestionIndex}`);
    };

    const handleNextClick = () => {
        navigate(`/quiz/${currentQuestionIndex + 2}`);
    };

    const handleSubmit = () => {
        sumAndSortOptions();
        navigate('/quiz/form');
    }

    return (
        <div className="vocational-quiz-wrapper questions-wrapper">
            <NavBar />
            <div className="question-image-placeholder">
                <img src={question.gif} alt="Question" />
            </div>
            <h2 className="question-component-text">{question.title}</h2>
            {
                question.options.map((option, index) => (
                    <button
                        key={index}
                        className={`option-button ${option.selected ? 'selected' : ''}`}
                        onClick={() => handleOptionClick(index)}
                    >
                        <b style={{ marginRight: '1rem', color: '#3F3F3F', padding: '0 5px 0 5px', alignSelf: 'flex-start', background: '#D9D9D9', borderRadius: 9999 }}>{String.fromCharCode(65 + index)}</b>  {option.text}
                    </button>
                ))
            }
            <div className="button-container">
                {currentQuestionIndex > 0 &&
                    <button type="button" className="question-button" onClick={handlePreviousClick}>Anterior</button>
                }
                &nbsp;
                {currentQuestionIndex < questionsArray.length - 1 ?
                    <button button type="button" className="question-button" onClick={handleNextClick}>Próxima pergunta</button>
                    :
                    <button type="button" className="question-button" onClick={handleSubmit}>Ultima etapa</button>
                }

            </div>
        </div >
    );
};

export default QuestionComponent;