import { createContext, useContext, useState } from "react";

import gif1 from '../assets/gifs/q1.gif'
import gif2 from '../assets/gifs/q2.gif'
import gif3 from '../assets/gifs/q3.gif'
import gif4 from '../assets/gifs/q4.gif'
import gif5 from '../assets/gifs/q5.gif'
import gif6 from '../assets/gifs/q6.gif'
import gif7 from '../assets/gifs/q7.gif'

export const QuizContext = createContext();

export function useQuizContext() {
    return useContext(QuizContext);
}

export default function QuizContextProvider({ children }) {
    const [questionsArray, setQuestionsArray] = useState(
        [
            {
                title: "Quando você pensa em um projeto de escola ou faculdade, você prefere:",
                gif: gif1,
                options:
                    [
                        { text: "Planejar e organizar tudo", selected: false, a1: 1, a2: 0, a3: 0, a4: 0 },
                        { text: "Criar e inovar com sugestões e ideias", selected: false, a1: 0, a2: 1, a3: 0, a4: 0 },
                        { text: "Fazer a pesquisa, trabalhando com números e dados", selected: false, a1: 0, a2: 0, a3: 1, a4: 0 },
                        { text: "Ajudar os colegas e trabalhar em equipe", selected: false, a1: 0, a2: 0, a3: 0, a4: 1 }
                    ]
            },
            {
                title: "Se você precisasse escolher uma dessas atividades para inserir na sua rotina qual seria?",
                gif: gif2,
                options:
                    [
                        { text: "Lendo sobre liderança e gestão", selected: false, a1: 1, a2: 0, a3: 0, a4: 0 },
                        { text: "Desenhando ou pintando", selected: false, a1: 0, a2: 1, a3: 0, a4: 0 },
                        { text: "Resolvendo problemas matemáticos", selected: false, a1: 0, a2: 0, a3: 1, a4: 0 },
                        { text: "Ajudando em projetos sociais", selected: false, a1: 0, a2: 0, a3: 0, a4: 1 },
                    ]
            },
            {
                title: "Quais dessas matérias você mais gostava na escola?",
                gif: gif3,
                options:
                    [
                        { text: "História ou Geografia", selected: false, a1: 1, a2: 0, a3: 0, a4: 0 },
                        { text: "Artes ou Educação Física", selected: false, a1: 0, a2: 1, a3: 0, a4: 0 },
                        { text: "Matemática ou Física", selected: false, a1: 0, a2: 0, a3: 1, a4: 0 },
                        { text: "Português ou Filosofia", selected: false, a1: 0, a2: 0, a3: 0, a4: 1 },
                    ]
            },
            {
                title: "O que você faria se ganhasse uma quantia significativa de dinheiro?",
                gif: gif4,
                options:
                    [
                        { text: "Investiria em um negócio próprio", selected: false, a1: 1, a2: 0, a3: 0, a4: 0 },
                        { text: "Viajaria pelo mundo", selected: false, a1: 0, a2: 1, a3: 0, a4: 0 },
                        { text: "Pouparia ou investiria no mercado de ações", selected: false, a1: 0, a2: 0, a3: 1, a4: 0 },
                        { text: "Doaria boa parte para a caridade por causas ambientais", selected: false, a1: 0, a2: 0, a3: 0, a4: 1 },
                    ]
            },
            {
                title: "Como você se descreveria?",
                gif: gif5,
                options:
                    [
                        { text: "Líder nato e motivador", selected: false, a1: 1, a2: 0, a3: 0, a4: 0 },
                        { text: "Criativo e cheio de ideias", selected: false, a1: 0, a2: 1, a3: 0, a4: 0 },
                        { text: "Analítico e detalhista", selected: false, a1: 0, a2: 0, a3: 1, a4: 0 },
                        { text: "Empático e solidário", selected: false, a1: 0, a2: 0, a3: 0, a4: 1 },
                    ]
            },
            {
                title: "Em um trabalho em grupo, você geralmente é o que:",
                gif: gif6,
                options:
                    [
                        { text: "Toma a liderança e delega tarefas", selected: false, a1: 1, a2: 0, a3: 0, a4: 0 },
                        { text: "Propõe ideias criativas e soluções fora da caixa", selected: false, a1: 0, a2: 1, a3: 0, a4: 0 },
                        { text: "Cuida dos detalhes e da precisão das informações", selected: false, a1: 0, a2: 0, a3: 1, a4: 0 },
                        { text: "Garante que todos estejam cooperando e se sentindo incluídos", selected: false, a1: 0, a2: 0, a3: 0, a4: 1 },
                    ]
            },
            {
                title: "Quais desses programas de TV ou filmes você prefere assistir?",
                gif: gif7,
                options:
                    [
                        { text: "Shark Tank ou O aprendiz", selected: false, a1: 1, a2: 0, a3: 0, a4: 0 },
                        { text: "Casa Vogue ou Master Chef", selected: false, a1: 0, a2: 1, a3: 0, a4: 0 },
                        { text: "The Big Bang Theory ou Mr. Robot", selected: false, a1: 0, a2: 0, a3: 1, a4: 0 },
                        { text: "Grey's Anatomy ou Central do Brasil", selected: false, a1: 0, a2: 0, a3: 0, a4: 1 },
                        { text: "Não conheço as series", selected: false, a1: 0, a2: 0, a3: 0, a4: 0 }
                    ]
            }
        ]
    )
    const [formData, setFormData] = useState({
        conversion_identifier: 'Alcides Quiz',
        email: '',
        cf_seu_nome: '',
        cf_seu_telefone: '51',
        cf_resultado_do_quiz: ''
    });
    return (
        <QuizContext.Provider value={{
            questionsArray,
            setQuestionsArray,
            formData,
            setFormData
        }}>
            {children}
        </QuizContext.Provider>
    );
}
